.table-component {
    flex: 1 1 0;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    .spin {
        margin: 30px;   
    }
    .ant-table-wrapper {
        width: 100%;
    }
    .table-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: flex-end;
    }
}