.transactions-report {
    tbody {
        tr:last-of-type {
            * {
                border-color: #1890ff;
            }
        }
        tr:nth-last-of-type(2) {
            * {
                border-bottom-color: #1890ff;
            }
        }
    }
    .sum {
        border-left: 1px solid #e8e8e8;
    }
    .spin {
        margin: 30px;   
    }
    .ant-table-wrapper {
        width: 100%;
    }
}