.filters-section {
    margin-bottom: 10px;

    .controls {
        padding: 10px;
    }

    .toggler-wrapper {
        display: flex;
        justify-content: center;
    }
    .toggler {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px;
        & > i {
            margin: 0 5px;
        }
        &:hover {
            background-color: rgb(230, 230, 230);
            cursor: pointer;
        }
    }
}