@import 'styles/_variables.scss';

.redux-modal {
    &.no-footer {
        .ant-modal-footer {
            display: none;
        }
        hr {
            border: none;
            height: 1px;
            background: #e8e8e8;
        }
    }

    .controlls {
        margin-top: 10px;
        button:first-of-type {
            margin-right: 10px;
        }
    }
}