//@import 'styles/_reset.scss';
@import 'styles/_fonts.scss';
@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';

//  Global layout

* {
    box-sizing: border-box;
}

.root {
    display: flex;
    flex-direction: column;
    height: 100vh;
    color: $darkBlue;
}
.app-header-wrapper {
    height: 100px;
}

.container {
    @include tablet {
        margin-left: 20px;
        margin-right: 20px;
    }
    @include desktop {
        margin-left: 100px;
        margin-right: 100px;
    }

    &.table {
        margin-left: 20px;
        margin-right: 20px;
        overflow-x: scroll;
        
        @include tablet {
            margin-left: 50px;
            margin-right: 50px;
        }
        @include desktop {
            margin-left: 100px;
            margin-right: 100px;
        }
    }

    &.wide-table {
        margin-left: 0px;
        margin-right: 0px;
        overflow-x: scroll;

        & .ant-table-thead > tr > th, .ant-table-tbody > tr {
            .cell {
                /* overflow-wrap: break-word; */
                max-width: 300px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                display: block;

                &.pointer {
                    cursor: pointer;
                }
            }
        }
    }
}

.filter-buttons {
    display: flex;
    justify-content: space-around;
    padding: 0 100px;

    .export, .apply {
        max-width: 200px;
        width: 40%;
        margin-bottom: 10px;
    }
}

a {
    color: $blue;
    &:hover {
        color: $skyBlue;
    }
}

.ant-form-item label > .anticon.anticon {
    vertical-align: middle;
}

.ant-select, .ant-calendar-picker {
    width: 100% !important;
}

.ant-select-dropdown .clear-select {
    color: red;
}

.ant-form-item.ant-form-item {
    margin-bottom: 15px;
}

.ant-modal-root {
    .agreement-modal {
        hr {
            display: none;
        }
        .ant-btn {
            color: #fff;
            font-size: 20px;
            font-weight: 700;
            padding: 20px;
            display: flex;
            justify-content: center;
            align-items: center;

            &.approve {
                background-color: #327432;
            }
            &.deny {
                background-color: #d9363e;
            }
        }
    }
}


.mobile {
  display: flex;
  @include tablet {
    display: none;
  }
  @include desktop {
    display: none;
  }
}
.not-mobile {
    display: none;
    @media (min-width: $tablet) {
        display: flex;
    }
}
.desktop {
    display: none;
    @media (min-width: $desktop) {
        display: flex;
    }
}

.text {
    color: $darkBlue !important;
}

h1, h2, h3, h4, h5 {
    & {
        @extend .text;
    }
}

h2 {
    font-size: 18px;
}
h3 {
    font-size: 16px;
}
h4 {
    font-size: 14px;
}
h5 {
    font-size: 12px;
}
