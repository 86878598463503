.prev-next-control {
    display: flex;
    align-items: center;
    margin: 10px;

    .current-page {
        margin-right: 10px;
    }
    .ctrl {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        border: 1px solid #1890ff;
        cursor: pointer;

        &.disabled {
            cursor: none;
            pointer-events: none;
            background-color: #e6e6e6;
        }

        &.next {
            border-left: none;
        }

        &:hover {
            background-color: #1890ff;
            color: white;
        }
    }
}