@import 'styles/_variables.scss';

.registration {
    height: 100%;
    .form {
        border: 1px solid $skyBlue;
        border-radius: 4px;
        margin-top: 40px;
        .title {
            border-bottom: 1px solid $skyBlue;
            padding: 10px;
            text-align: center;
        }
        .content {
            padding: 15px;
        }
        .ant-form-item {
            margin-bottom: 15px;
        }
    }
}
