//  **********  COLORS  **********
$grey: #C9C9C9;
$darkBlue: #153B50;
$aqua: #40C9A2;
$black: #171A21;
$white: #F7F7FF;
$blue: #4F7CAC;
$skyBlue: #74B6FC;
$lightGrey: #f8f8f8;
$navLink: #1890ff;


//  **********  BREAKPOINTS  **********
$tablet: 768px;
$desktop: 1024px;


//  **********  BORDERS  **********
$border: 'asd'; 


//  **********  BOX-SHADOWS  **********
$boxShadow: 'qwe';

