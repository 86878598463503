.users-admin {
    height: 100%;
    widows: 100%;
    .spin {
        display: block;
        margin: 30px auto;
    }

    .active {
        color: green;
    }

    .inactive {
        color: red;
    }
}