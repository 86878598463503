@import 'styles/_variables.scss';

.auth {
    .header {
        background-color: $white;
        border-bottom: 1px solid $skyBlue;
        .header-links {
            text-align: end;
            & a:last-child {
                margin-left: 15px;
            }
            & a.active {
                font-weight: 700;
            }
        }
    }
}
