.events {
    height: 100%;

    .events-list {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 5px 15px 5px 5px;
        overflow: scroll;
        .spin {
            margin: 30px;   
        }
    }
}