@import 'styles/_variables.scss';

.automaton-card {
    position: relative;
    background: $lightGrey;
    width: 95%;
    border-radius: 6px;
    border: 1px solid #e7e7e7;
    margin-top: 15px;
    margin-right: 10px;

    .online-status {
        position: absolute;
        top: 5px;
        right: 5px;
        font-weight: 500;

        i {
            display: inline-block;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background: grey;
            margin-left: 10px;
            vertical-align: middle;

            &.online {
                background: rgb(0, 200, 0);
            }
        }
    }

    .content {
        padding: 20px;
        margin-bottom: 10px;
    }

    .automaton-events {
        &.hide {
            display: none;
        }
    }

    .toggler-wrapper {
        display: flex;
        justify-content: center;
        font-size: 12px;
    }

    .toggler-wrapper.hide {
        background-color: #d9363e;
    }
    .toggler {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px;
        & > i {
            margin: 0 5px;
        }
        &:hover {
            background-color: rgb(230, 230, 230);
            cursor: pointer;
        }
    }

    .toggler.hide {
        &:hover {
            background-color: rgb(243, 110, 110);
        }
    }

    .event {
        width: 100%;
        padding: 5px 10px;
        border-bottom: 1px solid $darkBlue;

        &:last-child {
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            border-bottom: none;
        }

        &.error {
            background-color: red;
        }
        &.warning {
            background-color: yellow;
        }
    }

    &:last-child {
        margin-bottom: 15px;
    }

    .main-info {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        padding-bottom: 10px;
        border-bottom: 1px solid #e7e7e7;
    }
    .release-water {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
}
