.automatons {
    height: 100%;
    .automatons-list {
        flex: 1 1 0;
        overflow: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        .spin {
            margin: 30px;   
        }
    }
}