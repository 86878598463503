@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';

.app-header {
    z-index: 10;
    position: relative;
    height: 50px;
    margin: 15px 0;
    align-items: center;
    display: flex;
    background: $lightGrey;
    border-radius: 6px;
    border: 1px solid #e7e7e7;
    .menu {
        display: flex;
        justify-content: space-between;
        height: 100%;
        width: 100%;
        margin: 0 10px;
        & > div {
            height: 100%;
            display: flex;
        }
        a {
            font-size: 16px;
            padding: 10px 20px;
            position: relative;
        }
        a.active {
            border-bottom: 2px solid #1890ff;
        }
        a.dropdown-item.active {
            border-bottom: none;
            font-weight: bold;
        }
        .left {
            .menu-item:not(:first-child) {
                margin-left: 10px;
            }
        }
    }
}
