.prices-admin {
    height: 100%;
    widows: 100%;
    .spin {
        display: block;
        margin: 30px auto;
    }

    .active {
        background-color: yellow;
    }

    .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
        background: rgba(0,0,0,.05)
    }
}