.event-card {
    border-radius: 5px;
    box-shadow: 0 0 6px black;
    background-color: green;
    width: 100%;
    margin: 10px;
    padding: 20px;

    &:first-of-type {
        margin-top: 0;
    }

    hr {
        width: 100%;
        border: none;
        height: 2px;
        margin: 5px 0;
        background-color: rgba($color: #000000, $alpha: 0.3);
    }
}