@import 'styles/_variables.scss';

.menu-item {
    position: relative;
    display: flex;
    align-items: center;
    .dropdown-wrapper {
        height: 100%;
        min-width: 100%;
        position: absolute;
        transform: translateY(100%);
        .dropdown {
            background: $lightGrey;
            border-radius: 6px;
            min-width: 100%;
            border: 1px solid #e7e7e7;
            display: flex;
            flex-direction: column;
            position: absolute;
            top: 10px;
            overflow: hidden;
            .dropdown-item {
                padding: 5px;
                color: $navLink;
                cursor: pointer;
                &:hover {
                    background: $grey;
                }
            }
        }
    }
}