.balances-report {
  tbody {
    tr:last-of-type {
      * {
        border-color: #1890ff;
      }
    }

    tr:nth-last-of-type(2) {
      * {
        border-bottom-color: #1890ff;
      }
    }
  }
}
